var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex no-gutters align-items-center justify-content-between w-100",
    },
    [
      _c(
        "div",
        { staticClass: "col d-flex align-items-center text-ellipsis" },
        [
          _vm.get(_vm.location, "oneWayAllowed")
            ? _c("ui-icon", {
                staticClass: "mr-2",
                attrs: {
                  icon: _vm.ICONS.carPickUp,
                  size: _vm.ICONS_SIZES.medium,
                },
              })
            : _vm._e(),
          _c("div", [
            _vm._v(" " + _vm._s(_vm.get(_vm.location, "name", "Select")) + " "),
          ]),
        ],
        1
      ),
      _vm.location
        ? [
            _c(
              "div",
              {
                staticClass:
                  "d-flex col-2 mr-2 align-items-center emobg-font-x-small",
              },
              [
                _c("ui-icon", {
                  staticClass: "mr-1",
                  attrs: {
                    icon: _vm.ICONS.bold.location,
                    size: _vm.ICONS_SIZES.medium,
                  },
                }),
                _vm._v(
                  " " +
                    _vm._s(_vm.location.distanceFromLocation || 0) +
                    _vm._s(_vm.mileageUnit) +
                    " "
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex col-2 align-items-center emobg-font-x-small",
              },
              [
                _c("ui-icon", {
                  staticClass: "mr-1",
                  attrs: {
                    icon: _vm.ICONS.walking,
                    size: _vm.ICONS_SIZES.medium,
                  },
                }),
                _vm._v(
                  " " +
                    _vm._s(_vm.location.walkingTimeFromLocation || 0) +
                    "min "
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex col-1 flex-nowrap align-items-center emobg-font-x-small",
              },
              [
                _c("ui-icon", {
                  staticClass: "mr-1",
                  attrs: {
                    icon: _vm.ICONS.bold.vehicle,
                    size: _vm.ICONS_SIZES.medium,
                  },
                }),
                _vm._v(" " + _vm._s(_vm.location.carsAvailable || 0) + " "),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }