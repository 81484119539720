var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "WorkingHoursTemplate d-flex flex-column" },
    _vm._l(_vm.dedicatedHours, function (dedicatedHour, day) {
      return _c("div", { key: day, staticClass: "d-flex pb-1" }, [
        _c(
          "div",
          {
            staticClass: "WorkingHoursTemplate__day emobg-font-weight-semibold",
          },
          [_vm._v(" " + _vm._s(_vm.sentenceCase(day)) + ": ")]
        ),
        _c(
          "div",
          { staticClass: "WorkingHoursTemplate__hours d-flex flex-column" },
          [
            _vm.size(dedicatedHour)
              ? _vm._l(dedicatedHour, function (hourRange) {
                  return _c("div", { key: `${day}_${hourRange}` }, [
                    _vm._v(
                      " " + _vm._s(_vm.replace(hourRange, "-", " - ")) + " "
                    ),
                  ])
                })
              : _c("div", [_vm._v(" No dedicated hours ")]),
          ],
          2
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }