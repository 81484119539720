<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapMutations, mapState } from 'vuex';
import { COLORS, ICONS, PLACEMENTS } from '@emobg/vue-base';
import { MuiPopper } from '@emobg/motion-ui/v1';
import { STORE_REQUEST_STATUS } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import WorkingHoursTemplate from './components/WorkingHoursTemplate';
import { scopes } from '../../../../store/PlanningCreateModule';

export default {
  name: 'DedicatedHoursFeedback',
  components: {
    MuiPopper,
    WorkingHoursTemplate,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.planning.create, {
      dedicatedHoursStatus: state => state.dedicatedHours.STATUS,
      dedicatedHours: state => state.dedicatedHours.data,
    }),
    isFeedbackVisible() {
      return !isEmpty(get(this, 'dedicatedHours.workingHours', {})) && !isEmpty(this.notificationText);
    },
    notificationType() {
      return get(this, 'dedicatedHours.cannotBook') ? COLORS.danger : COLORS.primary;
    },
    iconType() {
      return get(this, 'dedicatedHours.cannotBook') ? ICONS.alertFull : ICONS.infoFull;
    },
    notificationText() {
      const infoText = !get(this, 'dedicatedHours.allowPersonalDedicated')
        ? 'Booking outside dedicated company hours will have an extra cost.'
        : '';
      const errorText = get(this, 'dedicatedHours.allowPersonalDedicated')
        ? 'Employees with Personal profile can’t book on these hours. Vehicle dedicated to Business only.'
        : 'User can’t book on these hours. Vehicle dedicated to company.';
      return get(this, 'dedicatedHours.cannotBook') ? errorText : infoText;
    },
  },
  created() {
    this.PLACEMENTS = PLACEMENTS;
  },
  methods: {
    get,
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.create, [
      'setStatus',
    ]),
    resetDedicatedHoursStatus() {
      this.setStatus({ scope: scopes.dedicatedHours, value: STORE_REQUEST_STATUS.idle });
    },
  },
};
</script>
<template>
  <div
    v-if="isFeedbackVisible"
    class="DedicatedHoursFeedback w-100 position-relative"
    data-test-id="dedicated-hours-feedback"
  >
    <ui-alert
      v-if="dedicatedHoursStatus.LOADED"
      :color="notificationType"
      class="w-100 animated fadeIn pb-2"
      data-test-id="alert"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <ui-icon
            :color="notificationType"
            :icon="iconType"
            :size="ICONS_SIZES.medium"
            data-test-id="icon"
          />
          <p
            class="mx-2"
            data-test-id="feedback-text"
            v-html="notificationText"
          />
        </div>
        <span
          class="emobg-link-primary emobg-body-2"
          data-test-id="dismiss-button"
          @click="resetDedicatedHoursStatus"
        >
          Dismiss
        </span>
      </div>
    </ui-alert>
    <div
      class="d-inline-flex align-items-center mt-3 pb-2"
      data-test-id="tooltip-wrapper"
    >
      <span class="emobg-font-weight-semibold pr-1"> Dedicated company hours </span>
      <div @mouseleave="isVisible = false">
        <MuiPopper
          :show="isVisible"
          :arrow="true"
          :arrow-class="`emobg-background-color-secondary`"
          :options="{
            placement: PLACEMENTS.bottom,
            modifiers: [{
              name: 'offset',
              options: {
                offset: [0, 12],
              },
            }]}"
          popper-class="fade"
        >
          <div @mouseenter="isVisible = true">
            <ui-icon
              :icon="ICONS.infoFull"
              :size="ICONS_SIZES.small"
              class="emobg-color-ink-light emobg-color-ink-hover"
              data-test-id="icon"
            />
          </div>
          <template #popper>
            <div class="v1-MuiTooltip__tooltip p-2 emobg-border-radius-medium emobg-caption-1 emobg-background-color-secondary emobg-color-white">
              <WorkingHoursTemplate :dedicated-hours="get(dedicatedHours, 'workingHours', {})" />
            </div>
          </template>
        </MuiPopper>
      </div>
    </div>
  </div>
</template>
