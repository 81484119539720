var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.booking
    ? _c(
        "div",
        {
          staticClass: "BookingInformation row",
          attrs: { "data-test-id": "booking_information" },
        },
        [
          _vm.showUserLink
            ? _c(
                "div",
                { staticClass: "col-12 mb-4" },
                [
                  _c("ui-label", { attrs: { label: "User" } }),
                  _c(
                    "PermissionLink",
                    {
                      staticClass: "emobg-caption-1",
                      attrs: {
                        "link-permissions": [_vm.CRM_PERMISSIONS.viewCrmUsers],
                        to: {
                          name: _vm.crm.users.detail.bookings,
                          params: {
                            userUuid: _vm.get(_vm.booking, "user.uuid"),
                          },
                        },
                        target: "_blank",
                        "data-test-id": "crm_users-link",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.get(
                              _vm.booking,
                              "user.name",
                              _vm.FALLBACK_MESSAGE.dash
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("CirclesFeedbackComponent"),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "col-md-6 mb-4" },
            [
              _c(
                "span",
                { staticClass: "d-block emobg-caption-2 pb-2 emobg-color-ink" },
                [_vm._v(" Booking type ")]
              ),
              _c("BookingTypeBadge", {
                attrs: {
                  type: _vm.bookingType,
                  "is-automatic": _vm.get(_vm.booking, "isAutomatic", false),
                  "data-test-id": "booking_type-badge",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col-md-6 mb-4" }, [
            _c(
              "span",
              { staticClass: "d-block emobg-caption-2 pb-2 emobg-color-ink" },
              [_vm._v(" System status ")]
            ),
            _c(
              "span",
              {
                staticClass:
                  "px-2 emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground emobg-border-radius-pill",
              },
              [
                _c("i", {
                  staticClass:
                    "d-inline-block icons-system-status align-middle",
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.sentenceCase(_vm.booking.systemStatus) ||
                        _vm.FALLBACK_MESSAGE.notAvailable
                    ) +
                    " "
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-md-6 mb-4" }, [
            _c(
              "span",
              { staticClass: "d-block emobg-caption-2 pb-2 emobg-color-ink" },
              [_vm._v(" Operator ")]
            ),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.get(
                      _vm.booking,
                      "operator.name",
                      _vm.FALLBACK_MESSAGE.dash
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-6 mb-4" }, [
            _c(
              "span",
              { staticClass: "d-block emobg-caption-2 pb-2 emobg-color-ink" },
              [_vm._v(" Vehicle status ")]
            ),
            _c(
              "span",
              {
                staticClass:
                  "px-2 emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground emobg-border-radius-pill",
              },
              [
                _c("span", {
                  staticClass:
                    "d-inline-block icons-vehicle-status align-middle",
                }),
                _vm.booking.isUnavailability
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.FALLBACK_MESSAGE.notAvailable) + " "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.sentenceCase(_vm.booking.vehicleStatus) ||
                              _vm.FALLBACK_MESSAGE.notAvailable
                          ) +
                          " "
                      ),
                    ]),
              ]
            ),
          ]),
          !_vm.booking.isUnavailability
            ? _c("div", { staticClass: "col-md-6 mb-4" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                  },
                  [_vm._v(" Profile ")]
                ),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.sentenceCase(
                          _vm.get(_vm.booking, "userProfile.type")
                        )
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.get(_vm.booking, "preBookingUuid")
            ? _c("div", { staticClass: "col-md-6 mb-4" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink",
                  },
                  [_vm._v(" Pre-booking UUID ")]
                ),
                _c("span", [
                  _vm._v(
                    " #" + _vm._s(_vm.get(_vm.booking, "preBookingUuid")) + " "
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }