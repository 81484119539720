<script>
import replace from 'lodash/replace';
import size from 'lodash/size';
import { sentenceCase } from '@emobg/web-utils';
export default {
  name: 'WorkingHoursTemplate',
  props: {
    dedicatedHours: {
      type: Object,
      required: true,
    },
  },
  methods: {
    replace,
    sentenceCase,
    size,
  },
};
</script>
<template>
  <div class="WorkingHoursTemplate d-flex flex-column">
    <div
      v-for="(dedicatedHour, day) in dedicatedHours"
      :key="day"
      class="d-flex pb-1"
    >
      <div class="WorkingHoursTemplate__day emobg-font-weight-semibold">
        {{ sentenceCase(day) }}:
      </div>
      <div class="WorkingHoursTemplate__hours d-flex flex-column">
        <template v-if="size(dedicatedHour)">
          <div
            v-for="hourRange in dedicatedHour"
            :key="`${day}_${hourRange}`"
          >
            {{ replace(hourRange, '-', ' - ') }}
          </div>
        </template>
        <div v-else>
          No dedicated hours
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .WorkingHoursTemplate {
    &__day {
      min-width: 75px;
    }

    &__hours {
      min-width: 144px;
    }
  }
</style>
