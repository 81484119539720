<script>
import get from 'lodash/get';
import { mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { getMessageText } from '@/components/GlobalMessaging/GlobalMessagingComponent';
import { EXCEPTION_KEYS } from './const/exceptionKeys.const';

export default {
  props: {
    userUuid: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.planning.create, {
      bookingError: state => get(state, 'booking.error'),
      vehicleLocationsError: state => get(state, 'locations.error'),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.planning.edit, {
      editBookingError: state => get(state, 'error'),
      getEditBookingError: state => get(state, 'booking.error'),
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    error() {
      return this.identityCardError || this.bookingError || this.editBookingError || this.vehicleLocationsError || this.getEditBookingError;
    },
    identityCardError() {
      return get(this, 'bookingError.key') === EXCEPTION_KEYS.identityCardRequired
        ? {
          ...this.bookingError,
          message: `User has not provided an ID. To continue, go to the
            <a href="/user-company/users/${this.userUuid}/account" target="_blank">user's page</a> to add their ID document details.`,
        }
        : null;
    },
  },
  created() {
    this.getMessageText = getMessageText;
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.create, [
      'clearBookingError',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.edit, [
      'clearEditBookingError',
    ]),
    clearErrors() {
      this.clearBookingError();
      this.clearEditBookingError();
    },
  },
};
</script>
<template>
  <div
    v-if="error"
    class="w-100 position-relative"
    data-test-id="booking_actions_feedback"
  >
    <ui-alert
      :color="COLORS.danger"
      :dismissible="!getEditBookingError"
      :icon="ICONS.infoFull"
      class="animated fadeIn d-block pb-3 w-100"
      data-test-id="alert"
      @dismissAlert="clearErrors()"
    >
      <p v-html="getMessageText(error, $t, operatorTimezone)" />
    </ui-alert>
  </div>
</template>
