var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CostAllocationsComponent" },
    [
      _vm._l(_vm.companyCostAllocations, function (costAllocation) {
        return [
          costAllocation.inputType === "select"
            ? _c("MuiSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { isRequired: costAllocation.required },
                    expression: "{ isRequired: costAllocation.required }",
                  },
                ],
                key: costAllocation.uuid,
                staticClass: "w-100 my-4",
                attrs: {
                  options: _vm.map(
                    _vm.get(costAllocation, "children", []),
                    (child) => ({ value: child.uuid, label: child.code })
                  ),
                  disabled: _vm.disabled,
                  name: costAllocation.name,
                  label: `Cost allocation ${costAllocation.name}${
                    costAllocation.required ? "*" : ""
                  }`,
                  "model-value": _vm.findValueForSelect(costAllocation),
                  searchbox: {
                    threshold: 1,
                    placeholder: "Select code",
                  },
                  placeholder: "Select code",
                },
                on: {
                  change: function ($event) {
                    return _vm.onAutocompleteChange(costAllocation.uuid, $event)
                  },
                },
              })
            : _vm._e(),
          costAllocation.inputType === "text"
            ? _c("MuiInputText", {
                key: costAllocation.uuid,
                staticClass: "w-100",
                attrs: {
                  name: costAllocation.name,
                  disabled: _vm.disabled,
                  label: `Cost allocation ${costAllocation.name}${
                    costAllocation.required ? "*" : ""
                  }`,
                  "model-value": _vm.get(
                    _vm.matchedModel(costAllocation),
                    "value",
                    ""
                  ),
                  placeholder: "Add code",
                },
                on: {
                  change: function ($event) {
                    return _vm.onInputTextChange(costAllocation, $event)
                  },
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }