export const STARTED_USAGE_STATUS = [
  'opened',
  'started',
  'waiting_end',
  'waiting_cancellation',
  'late',
  'paused',
];

export const ENDED_USAGE_STATUS = [
  'finished',
  'finished_late',
  'not_used',
  'cancelled',
  'waiting_end',
  'waiting_cancellation',
];

export const MIN_COMMENT_CHARACTERS = 6;
