var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "BookingVehicleOptionComponent d-flex no-gutters align-items-center justify-content-between w-100",
      attrs: { "data-test-id": "booking_vehicle_option" },
    },
    [
      _c(
        "div",
        { staticClass: "col text-ellipsis d-flex align-items-center" },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.vehicleName) } }),
          _vm.vehicle
            ? _c(
                "span",
                [
                  _vm._v(" ("),
                  _vm.get(_vm.vehicle, "oneWayAllowed")
                    ? _c("ui-icon", {
                        staticClass: "mx-1",
                        attrs: {
                          icon: _vm.ICONS.carPickUp,
                          size: _vm.ICONS_SIZES.medium,
                        },
                      })
                    : _vm._e(),
                  _vm._v(_vm._s(_vm.get(_vm.vehicle, "category")) + ") "),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.vehicle
        ? [
            _c(
              "div",
              {
                staticClass:
                  "d-flex col-1 align-items-center emobg-font-x-small icon-wrapper",
              },
              [
                _c("ui-icon", {
                  staticClass: "icon-spacing",
                  attrs: {
                    size: _vm.ICONS_SIZES.medium,
                    icon: _vm.ICONS.carDevice,
                    "data-test-id": "car_device-icon",
                  },
                }),
                _vm._v(" " + _vm._s(_vm.carDeviceLabel) + " "),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex col-1 align-items-center justify-content-center emobg-font-x-small icon-wrapper",
              },
              [
                _vm.get(_vm.vehicle, "connectivityStatus")
                  ? _c("ConnectivityStatusComponent", {
                      attrs: {
                        status: _vm.get(_vm.vehicle, "connectivityStatus"),
                        "show-text": false,
                        "data-test-id": "connectivity_status",
                      },
                    })
                  : _c("span", { staticClass: "pr-2" }, [
                      _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "),
                    ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex col-1 align-items-center emobg-font-x-small icon-wrapper",
              },
              [
                _vm.get(_vm.vehicle, "fuelType") === _vm.FUEL_TYPES.electric
                  ? _c("BatteryLevel", {
                      attrs: {
                        "battery-level": _vm.get(_vm.vehicle, "fuelLevel"),
                        "danger-level": _vm.get(_vm.vehicle, "fuelDangerLevel"),
                        "fallback-message": `${_vm.FALLBACK_MESSAGE.dash}%`,
                        "text-class": "icon-spacing",
                        "data-test-id": "battery_level",
                      },
                    })
                  : [
                      _c("ui-icon", {
                        staticClass: "icon-spacing",
                        attrs: {
                          icon: _vm.ICONS.fuelDispenser,
                          size: _vm.ICONS_SIZES.medium,
                          "data-test-id": "fuel_level-icon",
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.vehicle.fuelLevel || _vm.FALLBACK_MESSAGE.dash
                          ) +
                          "% "
                      ),
                    ],
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex col-2 flex-nowrap align-items-center emobg-font-x-small",
                staticStyle: { "max-width": "80px", "padding-left": "14px" },
              },
              [
                _c("ui-icon", {
                  staticClass: "icon-spacing",
                  attrs: {
                    icon: _vm.ICONS.carBattery,
                    size: _vm.ICONS_SIZES.medium,
                    "data-test-id": "battery_level-icon",
                  },
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.vehicle.batteryLevel || _vm.FALLBACK_MESSAGE.dash
                    ) +
                    " V "
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }