<script>
import filter from 'lodash/filter';
import each from 'lodash/each';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import {
  MuiInputText,
  MuiSelect,
  Validate,
} from '@emobg/motion-ui/v1';

export default {
  name: 'CostAllocationsComponent',
  directives: {
    Validate,
  },
  components: {
    MuiSelect,
    MuiInputText,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      type: Array,
      default: () => [],
    },
    companyCostAllocations: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      values: [],
    };
  },
  computed: {
    isValid() {
      let countOfNotValid = 0;
      const costAllocationsRequired = filter(this.companyCostAllocations, { required: true });
      each(costAllocationsRequired, costAllocation => {
        const costAllocationRequiredFound = find(this.values, { parentUuid: costAllocation.uuid });
        if (!costAllocationRequiredFound || isEmpty(costAllocationRequiredFound.value)) {
          countOfNotValid += 1;
        }
      });
      return countOfNotValid === 0;
    },
  },
  watch: {
    model: {
      handler(newModel, oldModel) {
        if (!isEqual(newModel, oldModel)) {
          this.values = cloneDeep(newModel);
          this.$emit('isValid', this.isValid);
        }
      },
      deep: true,
      immediate: true,
    },
    isValid(value) {
      this.$emit('isValid', value);
    },
  },
  methods: {
    map,
    get,
    findValueForSelect(item) {
      const costAllocation = find(item.children, { uuid: get(this.matchedModel(item), 'value') });
      return get(costAllocation, 'uuid', null);
    },
    matchedModel({ uuid }) {
      return find(this.values, { parentUuid: uuid }) || null;
    },
    onChangeValue(uuid, model) {
      const itemIndex = findIndex(this.values, { parentUuid: uuid });

      if (itemIndex > -1) {
        this.values.splice(itemIndex, 1, model);
      } else if (model.value !== '') {
        this.values.push(model);
      }

      this.$emit('change', this.values);
    },
    onAutocompleteChange(parentUuid, value) {
      const model = {
        parentUuid,
        value,
        type: 'select',
      };

      this.onChangeValue(parentUuid, model);
    },
    onInputTextChange({ uuid, inputType }, event) {
      const model = {
        parentUuid: uuid,
        value: get(event, 'target.value'),
        type: inputType,
      };

      this.onChangeValue(uuid, model);
    },
  },
};
</script>
<template>
  <div class="CostAllocationsComponent">
    <template v-for="costAllocation in companyCostAllocations">
      <MuiSelect
        v-if="costAllocation.inputType === 'select'"
        :key="costAllocation.uuid"
        v-validate="{ isRequired: costAllocation.required }"
        :options="map(get(costAllocation, 'children', []), child => ({value: child.uuid, label: child.code }))"
        :disabled="disabled"
        :name="costAllocation.name"
        :label="`Cost allocation ${costAllocation.name}${costAllocation.required ? '*' : ''}`"
        :model-value="findValueForSelect(costAllocation)"
        :searchbox="{
          threshold: 1,
          placeholder: 'Select code'
        }"
        placeholder="Select code"
        class="w-100 my-4"
        @change="onAutocompleteChange(costAllocation.uuid, $event)"
      />

      <MuiInputText
        v-if="costAllocation.inputType === 'text'"
        :key="costAllocation.uuid"
        :name="costAllocation.name"
        :disabled="disabled"
        :label="`Cost allocation ${costAllocation.name}${costAllocation.required ? '*' : ''}`"
        :model-value="get(matchedModel(costAllocation), 'value', '')"
        placeholder="Add code"
        class="w-100"
        @change="onInputTextChange(costAllocation, $event)"
      />
    </template>
  </div>
</template>
