var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingActionsComponent",
      attrs: { "data-test-id": "booking_actions" },
    },
    [
      _c("label", { staticClass: "d-block emobg-font-weight-bold mb-2" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _vm.isUnavailability
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c(
                  "ui-dropdown",
                  {
                    staticClass: "BookingActionsComponent__dropdown",
                    attrs: {
                      color: _vm.COLORS.danger,
                      "text-color": _vm.GRAYSCALE.white,
                      loading: _vm.cancellingActionsStatus.LOADING,
                      arrow: "",
                      "data-test-id": "unavailability-list",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "pl-3 w-100 emobg-font-weight-semibold",
                        attrs: { slot: "trigger" },
                        slot: "trigger",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.unavailabilityDropdownTitle) + " "
                        ),
                      ]
                    ),
                    _c("ui-dropdown-actions", {
                      staticClass: "emobg-color-ink",
                      attrs: { slot: "content" },
                      domProps: { actions: _vm.unavailabilityActions },
                      slot: "content",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c(
                  "ui-dropdown",
                  {
                    staticClass: "BookingActionsComponent__dropdown",
                    attrs: {
                      color: _vm.COLORS.primary,
                      face: _vm.FACES.outline,
                      loading: _vm.startingActionsStatus.LOADING,
                      arrow: "",
                      "data-test-id": "starting-list",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "pl-3 w-100 emobg-font-weight-semibold",
                        attrs: { slot: "trigger" },
                        slot: "trigger",
                      },
                      [_vm._v(" Starting ")]
                    ),
                    _c("ui-dropdown-actions", {
                      staticClass: "emobg-color-ink",
                      attrs: { slot: "content" },
                      domProps: { actions: _vm.startingActions() },
                      slot: "content",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c(
                  "ui-dropdown",
                  {
                    staticClass: "BookingActionsComponent__dropdown",
                    attrs: {
                      color: _vm.COLORS.danger,
                      face: _vm.FACES.outline,
                      loading:
                        _vm.finishingActionsStatus.LOADING ||
                        _vm.bypassCodeStatus.LOADING,
                      arrow: "",
                      "data-test-id": "cancelling-list",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "pl-3 w-100 emobg-font-weight-semibold",
                        attrs: { slot: "trigger" },
                        slot: "trigger",
                      },
                      [_vm._v(" Finishing ")]
                    ),
                    _c("ui-dropdown-actions", {
                      staticClass: "emobg-color-ink",
                      attrs: { slot: "content" },
                      domProps: {
                        actions: _vm.finishingActions(_vm.hasStarted),
                      },
                      slot: "content",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c(
                  "ui-dropdown",
                  {
                    staticClass: "BookingActionsComponent__dropdown",
                    attrs: {
                      color: _vm.COLORS.danger,
                      "text-color": _vm.GRAYSCALE.white,
                      loading: _vm.cancellingActionsStatus.LOADING,
                      arrow: "",
                      "data-test-id": "cancelling-list",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "pl-3 w-100 emobg-font-weight-semibold",
                        attrs: { slot: "trigger" },
                        slot: "trigger",
                      },
                      [_vm._v(" Cancelling ")]
                    ),
                    _c("ui-dropdown-actions", {
                      staticClass: "emobg-color-ink",
                      attrs: { slot: "content" },
                      domProps: {
                        actions: _vm.cancellingActions(_vm.hasStarted),
                      },
                      slot: "content",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }