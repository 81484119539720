<script>
import find from 'lodash/find';
import get from 'lodash/get';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'CirclesFeedbackComponent',
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.planning.create, {
      circles: state => state.profiles.data.circles,
    }),
    hasCircles() {
      return get(this, 'circles.length');
    },
    userCirclesMessage() {
      const hasAnyFleetSegment = find(this.circles || [], circle => circle.fleetSegments);

      return hasAnyFleetSegment ? 'Belongs to circle(s) with fleet segment' : 'Belongs to circle(s) without fleet segment';
    },
  },
};
</script>
<template>
  <div
    v-if="hasCircles"
    class="d-flex pt-1 align-items-center"
  >
    <div class="mr-1">
      {{ userCirclesMessage }}
    </div>
    <ui-icon
      :icon="ICONS.userCircle"
      :size="ICONS_SIZES.medium"
    />
  </div>
</template>
