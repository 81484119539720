<script>
import get from 'lodash/get';
import { ConnectivityStatusComponent } from '@/components';
import BatteryLevel from '@/domains/Carsharing/components/BatteryLevel/BatteryLevel';
import { FUEL_TYPES } from '@/domains/Fleet/VehicleModels/const/index.const';
import { DEVICE_TYPES } from './const/booking.const';

export default {
  name: 'BookingVehicleOptionComponent',
  components: {
    ConnectivityStatusComponent,
    BatteryLevel,
  },
  props: {
    vehicle: {
      type: Object,
      default: null,
    },
  },
  computed: {
    carDeviceLabel() {
      return get(this, 'vehicle.isConnectedCarPlatform', false) ? DEVICE_TYPES.cloudboxx : DEVICE_TYPES.iboxx;
    },
    vehicleName() {
      if (get(this, 'vehicle')) {
        return `${get(this, 'vehicle.licensePlate')} - <b class="emobg-font-weight-semibold mr-1">${get(this, 'vehicle.model')}</b>`;
      }

      return 'Select';
    },
  },
  created() {
    this.FUEL_TYPES = FUEL_TYPES;
  },
  methods: {
    get,
  },
};
</script>

<template>
  <div
    class="BookingVehicleOptionComponent d-flex no-gutters align-items-center justify-content-between w-100"
    data-test-id="booking_vehicle_option"
  >
    <div class="col text-ellipsis d-flex align-items-center">
      <span v-html="vehicleName" />
      <span v-if="vehicle">
        (<ui-icon
          v-if="get(vehicle, 'oneWayAllowed')"
          :icon="ICONS.carPickUp"
          :size="ICONS_SIZES.medium"
          class="mx-1"
        />{{ get(vehicle, 'category') }})
      </span>
    </div>
    <template v-if="vehicle">
      <div class="d-flex col-1 align-items-center emobg-font-x-small icon-wrapper">
        <ui-icon
          :size="ICONS_SIZES.medium"
          :icon="ICONS.carDevice"
          data-test-id="car_device-icon"
          class="icon-spacing"
        />
        {{ carDeviceLabel }}
      </div>
      <div class="d-flex col-1 align-items-center justify-content-center emobg-font-x-small icon-wrapper">
        <ConnectivityStatusComponent
          v-if="get(vehicle, 'connectivityStatus')"
          :status="get(vehicle, 'connectivityStatus')"
          :show-text="false"
          data-test-id="connectivity_status"
        />
        <span
          v-else
          class="pr-2"
        >
          {{ FALLBACK_MESSAGE.dash }}
        </span>
      </div>
      <div class="d-flex col-1 align-items-center emobg-font-x-small icon-wrapper">
        <BatteryLevel
          v-if="get(vehicle, 'fuelType') === FUEL_TYPES.electric"
          :battery-level="get(vehicle, 'fuelLevel')"
          :danger-level="get(vehicle, 'fuelDangerLevel')"
          :fallback-message="`${FALLBACK_MESSAGE.dash}%`"
          text-class="icon-spacing"
          data-test-id="battery_level"
        />
        <template v-else>
          <ui-icon
            :icon="ICONS.fuelDispenser"
            :size="ICONS_SIZES.medium"
            data-test-id="fuel_level-icon"
            class="icon-spacing"
          />
          {{ vehicle.fuelLevel || FALLBACK_MESSAGE.dash }}%
        </template>
      </div>
      <div
        class="d-flex col-2 flex-nowrap align-items-center emobg-font-x-small"
        style="max-width: 80px; padding-left: 14px;"
      >
        <ui-icon
          :icon="ICONS.carBattery"
          :size="ICONS_SIZES.medium"
          data-test-id="battery_level-icon"
          class="icon-spacing"
        />
        {{ vehicle.batteryLevel || FALLBACK_MESSAGE.dash }} V
      </div>
    </template>
  </div>
</template>
<style lang="scss">
.BookingVehicleOptionComponent {
  .icon-spacing {
    margin-right: 2px;
  }

  .icon-wrapper {
    max-width: 40px !important;
  }
}
</style>
