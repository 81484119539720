var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.infoMessage || _vm.successMessage || _vm.errorMessage
    ? _c(
        "div",
        {
          staticClass: "w-100 position-relative",
          attrs: { "data-test-id": "booking_actions_feedback" },
        },
        [
          _vm.infoMessage
            ? _c(
                "ui-alert",
                {
                  staticClass: "animated fadeIn d-block pb-3 w-100",
                  attrs: {
                    color: _vm.COLORS.primary,
                    icon: _vm.ICONS.infoFull,
                    "data-test-id": "info_message",
                  },
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.infoMessage) },
                  }),
                ]
              )
            : _vm._e(),
          _vm.successMessage
            ? _c(
                "ui-alert",
                {
                  staticClass: "animated fadeIn d-block pb-3 w-100",
                  attrs: {
                    icon: _vm.ICONS.bold.check,
                    dismissible: "",
                    "data-test-id": "success_message",
                  },
                  on: { dismissAlert: _vm.resetBookingActions },
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.successMessage) },
                  }),
                ]
              )
            : _vm._e(),
          _vm.errorMessage
            ? _c(
                "ui-alert",
                {
                  staticClass: "animated fadeIn d-block pb-3 w-100",
                  attrs: {
                    color: _vm.COLORS.danger,
                    icon: _vm.ICONS.alertFull,
                    dismissible: "",
                    "data-test-id": "error_message",
                  },
                  on: { dismissAlert: _vm.resetBookingActions },
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.errorMessage) },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }