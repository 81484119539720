import get from 'lodash/get';
import size from 'lodash/size';
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import some from 'lodash/some';
import find from 'lodash/find';
import { getTextFromHtmlString } from '@/utils';
export const isResourceMatchingEventProp = (element, eventProp, filterProp) => {
  if (!(element && eventProp && filterProp)) {
    const message = 'Missing props';
    throw new Error(message);
  }
  const optionsSelected = get(element.filter, filterProp);
  if (size(optionsSelected)) {
    const getAllEvents = get(element, 'row.events.all', () => null);
    const events = getAllEvents();
    if (size(events)) {
      return find(events, event => {
        const targetValue = get(event, eventProp);
        return includes(optionsSelected, targetValue);
      });
    }
  }
  return false;
};

export const isResourceMatchingText = (element, text, direction) => {
  if (!isString(text)) {
    throw new Error('text is not a string');
  }
  if (!text.length || !element) {
    return false;
  }
  let innerText;
  let isMatchingInResource;
  const normalizeText = value => value.replace(/[\s-,]+/g, '');
  const pattern = (new RegExp(normalizeText(text), 'i'));
  if (element.row) {
    innerText = getTextFromHtmlString(element.row.data.html);
    isMatchingInResource = pattern.test(normalizeText(innerText));

    const children = element.row.children();
    const parent = element.row.parent();
    switch (direction) {
    // in case we have a direction, we keep it so we don't re-check rows
      case 'children':
        return isMatchingInResource || some(children, child => isResourceMatchingText(child, text, direction));
      case 'parent':
        return isMatchingInResource || isResourceMatchingText(parent, text, direction);
      default:
      // in case we don't have direction, it means we need to check in both ways
        return isMatchingInResource
      || isResourceMatchingText(parent, text, 'parent')
      || isResourceMatchingText(element, text, 'children');
    }
  }
  // we are in the "leaves" of the tree (no "row")
  innerText = getTextFromHtmlString(element.data.html);
  isMatchingInResource = pattern.test(normalizeText(innerText));
  return isMatchingInResource;
};
export const isResourceMatchingByProp = (element, elementProp, filterProp) => {
  if (!(element && elementProp && filterProp)) {
    const message = 'Missing props';
    throw new Error(message);
  }
  const optionsSelected = get(element.filter, filterProp);
  const targetValue = get(element.row, elementProp);
  const isMatchingInResource = includes(optionsSelected, targetValue);
  if (isMatchingInResource) {
    return true;
  }
  if (size(optionsSelected)) {
    const children = element.row.children();
    if (size(children)) {
      return some(children, child => isResourceMatchingByProp(child, elementProp, filterProp));
    }
    return false;
  }
  return false;
};
export const isEventMatchingByProp = (element, elementProp, filterProp) => {
  if (!(element && elementProp && filterProp)) {
    const message = 'Missing props';
    throw new Error(message);
  }
  const optionsSelected = get(element.filter, filterProp);

  if (size(optionsSelected)) {
    const targetValue = get(element.e, elementProp);
    return includes(optionsSelected, targetValue);
  }
  return false;
};

