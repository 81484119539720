var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingCommentsComponent emobg-caption-1 mt-3",
      attrs: { "data-test-id": "booking_comments" },
    },
    [
      _vm.isShowing
        ? _c(
            "div",
            { staticClass: "animated fadeIn" },
            _vm._l(_vm.comments, function (comment, index) {
              return _c("ul", { key: index }, [
                _c(
                  "li",
                  {
                    staticClass:
                      "d-flex flex-column pb-2 mb-3 emobg-border-bottom-1 emobg-border-color-ground",
                  },
                  [
                    _c("span", { staticClass: "emobg-color-ink-light pb-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.reformatDateTime(
                              comment.date,
                              _vm.DATE_FORMAT.defaultExtended,
                              _vm.operatorTimezone
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "emobg-color-ink emobg-font-weight-bold pb-2",
                      },
                      [_vm._v(" " + _vm._s(comment.user) + " ")]
                    ),
                    _c("span", [_vm._v(" " + _vm._s(comment.body) + " ")]),
                  ]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _c(
        "ui-button",
        {
          staticClass: "wmin-initial height--initial",
          staticStyle: { padding: "0 !important" },
          attrs: {
            color: _vm.COLORS.primary,
            face: _vm.FACES.text,
            "data-test-id": "toggle-button",
          },
          on: {
            clickbutton: function ($event) {
              _vm.isShowing = !_vm.isShowing
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.actionText) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }