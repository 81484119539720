var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isFeedbackVisible
    ? _c(
        "div",
        {
          staticClass: "DedicatedHoursFeedback w-100 position-relative",
          attrs: { "data-test-id": "dedicated-hours-feedback" },
        },
        [
          _vm.dedicatedHoursStatus.LOADED
            ? _c(
                "ui-alert",
                {
                  staticClass: "w-100 animated fadeIn pb-2",
                  attrs: {
                    color: _vm.notificationType,
                    "data-test-id": "alert",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("ui-icon", {
                            attrs: {
                              color: _vm.notificationType,
                              icon: _vm.iconType,
                              size: _vm.ICONS_SIZES.medium,
                              "data-test-id": "icon",
                            },
                          }),
                          _c("p", {
                            staticClass: "mx-2",
                            attrs: { "data-test-id": "feedback-text" },
                            domProps: {
                              innerHTML: _vm._s(_vm.notificationText),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "emobg-link-primary emobg-body-2",
                          attrs: { "data-test-id": "dismiss-button" },
                          on: { click: _vm.resetDedicatedHoursStatus },
                        },
                        [_vm._v(" Dismiss ")]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "d-inline-flex align-items-center mt-3 pb-2",
              attrs: { "data-test-id": "tooltip-wrapper" },
            },
            [
              _c("span", { staticClass: "emobg-font-weight-semibold pr-1" }, [
                _vm._v(" Dedicated company hours "),
              ]),
              _c(
                "div",
                {
                  on: {
                    mouseleave: function ($event) {
                      _vm.isVisible = false
                    },
                  },
                },
                [
                  _c(
                    "MuiPopper",
                    {
                      attrs: {
                        show: _vm.isVisible,
                        arrow: true,
                        "arrow-class": `emobg-background-color-secondary`,
                        options: {
                          placement: _vm.PLACEMENTS.bottom,
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 12],
                              },
                            },
                          ],
                        },
                        "popper-class": "fade",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "popper",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v1-MuiTooltip__tooltip p-2 emobg-border-radius-medium emobg-caption-1 emobg-background-color-secondary emobg-color-white",
                                  },
                                  [
                                    _c("WorkingHoursTemplate", {
                                      attrs: {
                                        "dedicated-hours": _vm.get(
                                          _vm.dedicatedHours,
                                          "workingHours",
                                          {}
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2437321414
                      ),
                    },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            mouseenter: function ($event) {
                              _vm.isVisible = true
                            },
                          },
                        },
                        [
                          _c("ui-icon", {
                            staticClass:
                              "emobg-color-ink-light emobg-color-ink-hover",
                            attrs: {
                              icon: _vm.ICONS.infoFull,
                              size: _vm.ICONS_SIZES.small,
                              "data-test-id": "icon",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }