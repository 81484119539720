import defaults from 'lodash/defaults';
import isNil from 'lodash/isNil';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  TIME_ZONE,
  isNilString,
} from '@emobg/web-utils';
import moment from 'moment-timezone';
export const formatDate = (date, options = {}) => {
  const fullOptions = defaults(
    options,
    {
      outputFormat: DATE_FORMAT.defaultExtended,
      inputFormat: DATE_FORMAT.filter,
      timezone: TIME_ZONE.default,
    },
  );
  const momentDate = moment.utc(date, fullOptions.inputFormat);

  return momentDate.isValid() ? momentDate.tz(fullOptions.timezone).format(fullOptions.outputFormat) : date;
};
export const formatNil = value => (isNilString(value) || isNil(value) ? FALLBACK_MESSAGE.dash : value);

