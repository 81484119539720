var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error
    ? _c(
        "div",
        {
          staticClass: "w-100 position-relative",
          attrs: { "data-test-id": "booking_actions_feedback" },
        },
        [
          _c(
            "ui-alert",
            {
              staticClass: "animated fadeIn d-block pb-3 w-100",
              attrs: {
                color: _vm.COLORS.danger,
                dismissible: !_vm.getEditBookingError,
                icon: _vm.ICONS.infoFull,
                "data-test-id": "alert",
              },
              on: {
                dismissAlert: function ($event) {
                  return _vm.clearErrors()
                },
              },
            },
            [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.getMessageText(_vm.error, _vm.$t, _vm.operatorTimezone)
                  ),
                },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }