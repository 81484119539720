var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasCircles
    ? _c(
        "div",
        { staticClass: "d-flex pt-1 align-items-center" },
        [
          _c("div", { staticClass: "mr-1" }, [
            _vm._v(" " + _vm._s(_vm.userCirclesMessage) + " "),
          ]),
          _c("ui-icon", {
            attrs: { icon: _vm.ICONS.userCircle, size: _vm.ICONS_SIZES.medium },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }