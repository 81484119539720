<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'BookingLocationOptionComponent',
  props: {
    location: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      mileageUnit: state => get(state, 'operators.mileageUnit', 'km'),
    }),
  },
  methods: {
    get,
  },
};
</script>

<template>
  <div class="d-flex no-gutters align-items-center justify-content-between w-100">
    <div class="col d-flex align-items-center text-ellipsis">
      <ui-icon
        v-if="get(location, 'oneWayAllowed')"
        :icon="ICONS.carPickUp"
        :size="ICONS_SIZES.medium"
        class="mr-2"
      />
      <div>
        {{ get(location, 'name', 'Select') }}
      </div>
    </div>
    <template v-if="location">
      <div class="d-flex col-2 mr-2 align-items-center emobg-font-x-small">
        <ui-icon
          :icon="ICONS.bold.location"
          :size="ICONS_SIZES.medium"
          class="mr-1"
        />
        {{ location.distanceFromLocation || 0 }}{{ mileageUnit }}
      </div>
      <div class="d-flex col-2 align-items-center emobg-font-x-small">
        <ui-icon
          :icon="ICONS.walking"
          :size="ICONS_SIZES.medium"
          class="mr-1"
        />
        {{ location.walkingTimeFromLocation || 0 }}min
      </div>
      <div class="d-flex col-1 flex-nowrap align-items-center emobg-font-x-small">
        <ui-icon
          :icon="ICONS.bold.vehicle"
          :size="ICONS_SIZES.medium"
          class="mr-1"
        />
        {{ location.carsAvailable || 0 }}
      </div>
    </template>
  </div>
</template>
