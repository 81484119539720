<script>
import get from 'lodash/get';
import { mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { getMessageText } from '@/components/GlobalMessaging/GlobalMessagingComponent';
import { BOOKING_ACTIONS, BOOKING_ACTIONS_ERRORS } from './const/bookingActions';

export default {
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.planning.edit, {
      bypassCode: state => state.bypassCode.data.code,
      bypassCodeStatus: state => state.bypassCode.STATUS,
      bookingActionsFeedback: state => state.bookingActionsFeedback.data,
      startingActionsStatus: state => state.startingActions.STATUS,
      finishingActionsStatus: state => state.finishingActions.STATUS,
      cancellingActionsStatus: state => state.cancellingActions.STATUS,
      forceFinishStatus: state => state.forceFinish.STATUS,
      cancellingActionsError: state => get(state, 'cancellingActions.error'),
    }),
    error() {
      return this.cancellingActionsError;
    },
    successMessage() {
      let message;
      const isSuccessTypeApplicable = [
        BOOKING_ACTIONS.activateBooking,
        BOOKING_ACTIONS.resendBooking,
        BOOKING_ACTIONS.terminateAsUser,
        BOOKING_ACTIONS.terminateAsAdmin,
      ].includes(this.bookingActionsFeedback);
      const isLoaded = this.startingActionsStatus.LOADED || this.cancellingActionsStatus.LOADED || this.finishingActionsStatus.LOADED;

      if (isSuccessTypeApplicable && isLoaded) {
        const actionText = this.$t(`Carsharing.Planning.BookingActions.${this.bookingActionsFeedback}`);
        const actionNode = `<span class="emobg-font-weight-bold text-lowercase">${actionText}</span>`;

        message = `Request to ${actionNode} was sent to the vehicle.`;
      } else if (BOOKING_ACTIONS.sendBypassCode === this.bookingActionsFeedback && this.bypassCodeStatus.LOADED) {
        message = 'Bypass code was sent to user via <span class="emobg-font-weight-bold">SMS</span>.';
      }

      return message;
    },
    errorMessage() {
      const isErrorStatus = this.startingActionsStatus.ERROR
        || this.cancellingActionsStatus.ERROR
        || this.finishingActionsStatus.ERROR
        || this.bypassCodeStatus.ERROR
        || this.forceFinishStatus.ERROR;

      if (isErrorStatus) {
        if (this.error && BOOKING_ACTIONS_ERRORS.includes(this.error.key)) {
          return getMessageText(this.exceptionMessage || this.error, this.$t, null);
        }

        const actionText = this.$t(`Carsharing.Planning.BookingActions.${this.bookingActionsFeedback}`);
        const actionNode = `<span class="emobg-font-weight-bold text-lowercase">${actionText}</span>`;

        return `We could not ${actionNode}! Try again`;
      }

      return null;
    },
    infoMessage() {
      if (this.bypassCode && this.bypassCodeStatus.LOADED) {
        return `Bypass code: <i class="emobg-font-weight-bold">${this.bypassCode}</i>`;
      }

      return null;
    },
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.edit, [
      'resetBookingActions',
    ]),
  },
};
</script>
<template>
  <div
    v-if="infoMessage || successMessage || errorMessage"
    class="w-100 position-relative"
    data-test-id="booking_actions_feedback"
  >
    <ui-alert
      v-if="infoMessage"
      :color="COLORS.primary"
      :icon="ICONS.infoFull"
      class="animated fadeIn d-block pb-3 w-100"
      data-test-id="info_message"
    >
      <span v-html="infoMessage" />
    </ui-alert>

    <ui-alert
      v-if="successMessage"
      :icon="ICONS.bold.check"
      dismissible
      class="animated fadeIn d-block pb-3 w-100"
      data-test-id="success_message"
      @dismissAlert="resetBookingActions"
    >
      <span v-html="successMessage" />
    </ui-alert>

    <ui-alert
      v-if="errorMessage"
      :color="COLORS.danger"
      :icon="ICONS.alertFull"
      dismissible
      class="animated fadeIn d-block pb-3 w-100"
      data-test-id="error_message"
      @dismissAlert="resetBookingActions"
    >
      <span v-html="errorMessage" />
    </ui-alert>
  </div>
</template>
