import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { DATE_FORMAT, reformatDateTime } from '@emobg/web-utils';
import moment from 'moment-timezone';
import { DATE } from '@/components/DayPilot/constants';
import {
  isEventMatchingByProp,
  isResourceMatchingByProp,
  isResourceMatchingEventProp,
  isResourceMatchingText,
} from '@/components/DayPilot/utils';

const onwWayIconHtml = '<i class="icons-car-pick-up icons-x-small mr-2"></i>';

export const popperOptions = { placement: 'auto-start', strategy: 'fixed' };
export const bookingPopperOptions = {
  ...popperOptions,
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8], // separation from reference object
      },
    },
  ],
};

export const dayPilotSeparators = timezone => [
  {
    color: 'Red',
    width: 2,
    layer: 'AboveEvents',
    location: reformatDateTime(moment.utc().format(DATE_FORMAT.filter), DATE.detail, timezone),
  },
];

export const dayPilotConfig = (timezone, onBeforeCellRender) => ({
  cellWidthMin: null,
  rowHeaderWidth: 250,
  rowMinHeight: 56,
  eventHeight: 56,
  separators: dayPilotSeparators(timezone),
  onBeforeRowHeaderRender: () => {},
  onBeforeCellRender,
});

export const dayPilotViewTypes = [
  {
    label: '1 day',
    config: {
      days: 1,
      scale: 'CellDuration',
      timeHeaders: [
        { groupBy: 'Day', format: 'dd/MM/yyyy' },
        { groupBy: 'Hour' },
      ],
      cellDuration: 15,
    },
  },
  {
    label: '3 days',
    config: {
      days: 3,
      scale: 'Hour',
      timeHeaders: [
        { groupBy: 'Month', format: 'MM/yyyy' },
        { groupBy: 'Day', format: 'd' },
        { groupBy: 'Hour' },
      ],
    },
  },
  {
    label: '7 days',
    config: {
      days: 7,
      scale: 'Day',
      timeHeaders: [
        { groupBy: 'Month', format: 'MM/yyyy' },
        { groupBy: 'Day', format: 'd' },
      ],
    },
  },
];

export const timezoneCorrector = (date, timezone, inputFormat = DATE_FORMAT.filter, outputFormat = DATE.detail) => (
  reformatDateTime(moment.utc(date, inputFormat).format(DATE_FORMAT.filter), outputFormat, timezone)
);

export const resourceLocationTransformer = location => {
  const oneWayIcon = location.isOneWayAllowed ? onwWayIconHtml : '';
  return {
    id: location.uuid,
    html: `
      <p data-location-uuid="${location.uuid}" class="d-flex align-items-center emobg-font-weight-bold">
        ${oneWayIcon}${location.name}
      </p>
    `,
    cssClass: 'PlanningView__parent-resource',
    locationUuid: location.uuid,
    children: [],
    expanded: true,
  };
};

export const resourceVehicleTransformer = (vehicle, vehicleUuid, locationUuid, canViewVehicleLink = false) => {
  const vehicleUrl = `/fleet/vehicles/${vehicleUuid}/details`;
  const vehicleLinkNode = canViewVehicleLink
    ? `<a data-vehicle-uuid="${vehicleUuid}"  href="${vehicleUrl}" class="emobg-link-primary emobg-body-2" target="_blank">${vehicle.license_plate}</a>`
    : `<span data-vehicle-uuid="${vehicleUuid}">${vehicle.license_plate}</span>`;
  const oneWayIcon = vehicle.one_way_allowed ? onwWayIconHtml : '';
  return {
    id: `${locationUuid}__${vehicleUuid}`,
    html: `
      <div class="d-flex align-items-center w-100">
        ${oneWayIcon}
        <div class="flex-fill text-right">
          ${vehicleLinkNode}<br/>
          <p>${vehicle.brand_name} - ${vehicle.model_name}</p>
        </div>
      </div>
    `,
    cssClass: 'PlanningView__resource',
    vehicle,
    vehicleUuid,
    locationUuid,
    customRanges: [],
  };
};

export const onRowFilter = ({ element, searchText }) => {
  const { filter } = element;
  const searchTextEscapedRegex = searchText.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '');
  const isMatchingBookingType = isEmpty(filter.bookingTypes) || isResourceMatchingEventProp(element, 'data.booking.type', 'bookingTypes');
  const isMatchingCategory = isEmpty(filter.categories) || isResourceMatchingByProp(element, 'data.vehicle.category_uuid', 'categories');
  const isMatchingLocation = isEmpty(filter.locations) || isResourceMatchingByProp(element, 'data.locationUuid', 'locations', 'uuid');
  const isMatchingOperator = isEmpty(filter.operators) || isResourceMatchingByProp(element, 'data.vehicle.cs_operator_uuid', 'operators');
  const isMatchingUsers = isEmpty(filter.users) || isResourceMatchingEventProp(element, 'data.booking.user_uuid', 'users');
  const isMatchingText = isEmpty(searchTextEscapedRegex) || isResourceMatchingText(element, searchTextEscapedRegex);
  const isFiltersMatch = isMatchingBookingType
      && isMatchingCategory
      && isMatchingLocation
      && isMatchingOperator
      && isMatchingText
      && isMatchingUsers;

  element.visible = isFiltersMatch;
};

export const onEventFilter = element => {
  const { filter } = element;
  const isUserMatch = isEmpty(filter.users) || isEventMatchingByProp(element, 'data.booking.user_uuid', 'users', 'uuid');
  const isMatchingBookingType = isEmpty(filter.bookingTypes) || isEventMatchingByProp(element, 'data.booking.type', 'bookingTypes', 'id');

  element.visible = (isUserMatch && isMatchingBookingType) || get(element, 'e.data.block');
};
