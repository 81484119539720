<script>
import { mapState } from 'vuex';
import { DATE_FORMAT, reformatDateTime } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShowing: false,
    };
  },
  computed: {
    actionText() {
      return this.isShowing ? '- Hide all comments' : '+ Show all comments';
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    reformatDateTime,
  },
};
</script>
<template>
  <div
    class="BookingCommentsComponent emobg-caption-1 mt-3"
    data-test-id="booking_comments"
  >
    <div
      v-if="isShowing"
      class="animated fadeIn"
    >
      <ul
        v-for="(comment, index) in comments"
        :key="index"
      >
        <li class="d-flex flex-column pb-2 mb-3 emobg-border-bottom-1 emobg-border-color-ground">
          <span class="emobg-color-ink-light pb-1"> {{ reformatDateTime(comment.date, DATE_FORMAT.defaultExtended, operatorTimezone) }} </span>
          <span class="emobg-color-ink emobg-font-weight-bold pb-2"> {{ comment.user }} </span>
          <span> {{ comment.body }} </span>
        </li>
      </ul>
    </div>

    <ui-button
      :color="COLORS.primary"
      :face="FACES.text"
      class="wmin-initial height--initial"
      style="padding: 0 !important;"
      data-test-id="toggle-button"
      @clickbutton="isShowing = !isShowing"
    >
      {{ actionText }}
    </ui-button>
  </div>
</template>
